<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div v-if="!isLoading">
        <div style="overflow-y: autos; white-space: nowrap;" class="p-3 lg:grid grid-cols-4 gap-4">
          <div class="col-span-2 ">
            <div class="card rounded-md p-2 mb-4">
              <div class="flex items-center justify-between">
                <div class="heading-4 text-text1 ">● User Info.:</div>
              </div>
              <div class="flex pl-2">
                <div class="w-full">
                  <div class="my-2">
                    <div class="gap-4 lg:grid grid-cols-2">
                      <div class="mb-4 sm:mb-0">
                        <TextField
                          :inputId="'contactnameinput1'"
                          :inputext="editUserInfo.firstName"
                          :placholderText="`First Name`"
                          :lableText="'First Name'"
                          :autoFocus="false"
                          :fieldError="editUserInfo.fnameErr !== ''"
                          @keyPressAction="editUserInfo.fnameErr = ''"
                          @inputChangeAction="(data) => editUserInfo.firstName = data"  />
                          <p class="text-error heading-7">{{editUserInfo.fnameErr}}</p>
                        </div>
                      <div class="mb-4 sm:mb-0">
                        <TextField
                          :inputId="'contactnameinput2'"
                          :inputext="editUserInfo.lastName"
                          :placholderText="`Last Name`"
                          :lableText="'Last Name'"
                          :autoFocus="false"
                          :fieldError="editUserInfo.lnameErr !== ''"
                          @keyPressAction="editUserInfo.lnameErr = ''"
                          @inputChangeAction="(data) => editUserInfo.lastName = data"  />
                          <p class="text-error heading-7">{{editUserInfo.lnameErr}}</p>
                      </div>
                      <div class="mb-4 sm:mb-0">
                        <NumberInput
                          :textMaxlength="12"
                          :inputType="'text'"
                          :inputId="'contactnameinput3'"
                          :inputext="editUserInfo.phoneNo"
                          :placholderText="`Cell Number`"
                          :lableText="'Cell Number'"
                          :autoFocus="false"
                          @blurAction="checkContactNumber()"
                          :fieldError="editUserInfo.cellErr !== ''"
                          @keyPressAction="editUserInfo.cellErr = ''"
                          @inputChangeAction="(data) => editUserInfo.phoneNo = data"  />
                          <p class="text-error heading-7">{{editUserInfo.cellErr}}</p>
                      </div>
                    </div>
                  </div>
              </div>
              </div>
            </div>
            <div class="card rounded-md p-2 mb-4">
              <div class="pl-2 py-2">
                <div class="sm:grid grid-cols-2 gap-4 mb-4">
                  <div class="mb-4 sm:mb-0">
                    <TextField
                      :inputId="`useremailid`"
                      :inputext="editUserInfo.emailAddress"
                      :placholderText="`Email Address`"
                      :lableText="'Email Address'"
                      :fieldError="emailErr !== ''"
                      :autoFocus="false"
                      :inputType="'text'"
                      @keyPressAction="emailErr = ''"
                      @blurAction="emailHandler(editUserInfo.emailAddress)"
                      @inputChangeAction="(data) => editUserInfo.emailAddress = data"  />
                      <p class="text-error heading-7"> {{ emailErr }}</p>
                  </div>
                  <div class="mb-4 sm:mb-0">
                    <TextField
                      :inputId="`userpassid`"
                      :inputext="editUserInfo.userPass"
                      :placholderText="`Password`"
                      :lableText="'Password'"
                      :fieldError="passErr !== ''"
                      :autoFocus="false"
                      :inputType="'password'"
                      @keyPressAction="passErr = ''"
                      @inputChangeAction="(data) => editUserInfo.userPass = data"  />
                      <p class="text-error heading-7"> {{ passErr }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card rounded-md p-3 mb-4">
              <div class="flex items-center">
              <label class="cu_switch cursor-pointer mr-3" :class="!editUserInfo.isActive ? 'ml-2' : ''">
                <input type="checkbox" v-model="editUserInfo.isActive">
                <span class="cu_slider cu_round"></span>
              </label>
              <p class="text-gray3 pl-1">{{editUserInfo.isActive ? 'This is staff is Active' : 'This is staff is Inactive'}}</p>
              </div>
            </div>
            <div class="card rounded-md sticky bottom-0 bg-white w-full flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                  <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="saveNewAddress(false)"/>
                  <Button :btnSize="'medium'"  :textColor="'white'" :btnColor="'success'" :btnText="`Save`" @buttonAction="saveNewAddress(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-show="scY > 200" @click="toTop" class="bg-primary rounded-full h-10 w-10 fixed bottom-3 right-2"><i class="fas fa-caret-up text-white"></i></button>
    <div v-if="editUserLogin" class="popup_overlay relative px-4">
      <div style="width: 800px;" class="custom_dialog rounded-xl h-min p-4">
        <div class="flex items-center justify-between">
          <div class="heading-4 text-text1 ">User Info.:</div>
          <div class="flex items-center gap-3">
            <div class=" text-white heading-5 cursor-pointer bg-error px-2.5 py-1 rounded-full" @click.stop="editUserLogin=false">
              <i class="fas fa-times"></i>
            </div>
            <div class=" text-white heading-5 cursor-pointer bg-secondary px-2.5 py-1 rounded-full" @click.stop="editUserLogin=false">
              <i class="fas fa-check"></i>
            </div>
          </div>
        </div>
        <div class="flex pl-2">
            <div class="w-full">
                <div class="">
                  <div class="gap-4 lg:grid grid-cols-2">
                    <div class="mb-4 sm:mb-0">
                      <TextField
                        :inputId="'contactnameinput1'"
                        :inputext="editUserInfo.firstName"
                        :placholderText="`First Name`"
                        :lableText="'First Name'"
                        :autoFocus="false"
                        @inputChangeAction="(data) => editUserInfo.firstName = data"  />
                      </div>
                    <div class="mb-4 sm:mb-0">
                      <TextField
                        :inputId="'contactnameinput2'"
                        :inputext="editUserInfo.lastName"
                        :placholderText="`Last Name`"
                        :lableText="'Last Name'"
                        :autoFocus="false"
                        @inputChangeAction="(data) => editUserInfo.lastName = data"  />
                    </div>
                    <div class="mb-4 sm:mb-0">
                      <TextField
                        :inputId="'contactnameinput3'"
                        :inputext="editUserInfo.phoneNo"
                        :placholderText="`Cell Number`"
                        :lableText="'Cell Number'"
                        :autoFocus="false"
                        @inputChangeAction="(data) => editUserInfo.phoneNo = data"  />
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getEncryptedPassword} from '@/utils/encrytPassword'
import NumberInput from '@/View/components/number-text-field.vue'
import { validateEmail, checkSpeialChar } from "@/utils/validation.js";
import TextField from '@/View/components/textfield.vue'
// import TextAreaInput from '@/View/components/textAreaInput.vue'
import Button from '@/View/components/globalButton.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    TextField,
    NumberInput,
    Button,
    // TextAreaInput
  },
  mixins: [deboucneMixin],
  data () {
    return {
      numberExist: false,
      emailExist: false,
      passErr: '',
      emailErr: '',
      emailValid: true,
      openLoginEdit: false,
      openUserIsEdit: false,
      editUserLogin: false,
      message: '',
      editUserInfo: {
        firstName: '',
        lastName: '',
        phoneNo: '',
        fnameErr: '',
        lnameErr: '',
        cellErr: '',
        emailAddress: '',
        userPass: '',
        isActive: true,
      },
      istart: false,
      assignStaff: [
        {name: 'Rich Martin'},
        {name: 'Erika Cowell'},
        {name: 'Michael Bloomberg'},
        {name: 'Chris Rock'},
        {name: 'Dave Goodman'}
      ],
      readMoreNote: false,
      datePickerVal: false,
      updateExpiryDate: '',
      openEditPopup: false,
      isLoading: false,
      compDetail: null,
      planDetail: [],
      scY: 0,
      scTimer: 0,
      useDetailId: 0,
      isShowFullDesc: true,
      cellValid: true,
      isShowShortDesc: true,
      mobileView: false,
      competitionDetailObj: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.updateExpiryDate = new Date()
    window.scrollTo({top: 0})
    window.addEventListener('scroll', this.handleScroll);
    this.resizeWindowHandler();
     document.title = 'Staff Add'
    // this.getCompetDetail()
  },
  watch: {
    competitionDetailObj: {
      handler () {
        console.log('Watch On:-competitionDetailObj', this.competitionDetailObj)
      },
      deep: true
    }
  },
  methods: {
    checkContactNumber () {
      let value = checkSpeialChar(this.editUserInfo.phoneNo)
      console.log('value', value)
      if (value) {
        this.cellValid = false
        this.editUserInfo.cellErr = 'Please enter valid contact number'
      } else if (this.editUserInfo.phoneNo.length < 10) {
        this.cellValid = false
        this.editUserInfo.cellErr = 'Please enter valid 10 digit contact number'
      } else {
        this.cellValid = true
        this.editUserInfo.cellErr = ''
        this.checkExist('phone', this.editUserInfo.phoneNo, 0)
      }
    },
    saveNewAddress (isSave) {
      if (!isSave) {
        this.$router.push({name: 'StaffListing'})
      } else {
        let isvalid = true
        if (this.editUserInfo.firstName === '') {
          this.editUserInfo.fnameErr = 'First name is required'
          isvalid = false
        }
        if (this.editUserInfo.lastName === '') {
          this.editUserInfo.lnameErr = 'Last name is required'
          isvalid = false
        }
        if (this.editUserInfo.phoneNo === '') {
          this.editUserInfo.cellErr = 'Cell Number is required'
          isvalid = false
        }
        if (this.editUserInfo.userPass === '') {
          this.passErr = 'Password is required'
          isvalid = false
        }
        if (this.editUserInfo.emailAddress === '') {
          this.emailErr = 'Email Address is required'
          isvalid = false
        }
        if (this.numberExist) {
          isvalid = false
        }
        if (this.emailExist) {
          isvalid = false
        }
        if (!this.emailValid) {
          isvalid = false
        }
        if (!this.cellValid) {
          isvalid = false
        }
        if (isvalid) {
          this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
          this.editUserInfo.password = getEncryptedPassword(this.editUserInfo.userPass)
          ADMINAPI.NewStaffSave(
            this.editUserInfo,
            response => {
              this.$router.push({name: 'StaffListing'})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
          )
        }
      }
    },
    emailHandler (emailId) {
      this.emailErr = this.emailValidation(emailId)
      console.log('emailErr', this.emailErr)
      console.log('emailErr', this.emailValidation(emailId))
      if (this.emailErr === 'Email address is invalid') {
        this.emailValid = false
      } else {
        this.emailValid = true
        this.emailErr = ''
        this.checkExist('email', this.editUserInfo.emailAddress, 0)
      }
    },
    emailValidation (emailId) {
      if (emailId === '') {
        return ''
      } else if (validateEmail(emailId) === false) {
        return 'Email address is invalid'
      } else {
        return ''
      }
    },
    checkExist (from, value, contact) {
      ADMINAPI.CheckNumberExistStaff(
        from,
        value,
        contact,
        response => {
          if (from === 'phone' && response.Data) {
            this.numberExist = true
            this.editUserInfo.cellErr = 'Phone number already exist'
          } else if (from === 'email' && response.Data) {
            this.emailExist = true
            this.emailErr = 'Email Address already exist' 
          } else {
            this.numberExist = false
            this.emailExist = false
          }
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      ) 
    },
    userEdit () {
      this.openUserIsEdit = true
      // this.editUserLogin = true
    },
    logInDataEdit () {
      this.openLoginEdit = true
    },
    smoothScroll (target) {
      window.scrollTo({
        top: document.getElementById(target).offsetTop - 60,
        left: 0,
        behavior: "smooth",
      });
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop () {
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    redirectToList() {
      // this.$router.push({name: 'Competition'})
      this.$router.go(-1)
    },
  }
}
</script>
<style scoped>

.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.list_top_card_btn {
  width: 83px !important;
  margin-right: 15px !important;
}
.ImageTag {
  max-height: 300px;
  width: auto;
}
</style>
